import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import "./styles.scss";
import ReactGA from "react-ga4";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// ReactGA.initialize("G-66SSXJQTBF");
ReactGA.initialize("G-BC3TM7EPB5");
// if (process.env.NODE_ENV === "production") {
LogRocket.init("gbijfc/podcast-fellowship");
setupLogRocketReact(LogRocket);
// }

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
